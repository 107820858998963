import {
    Calendar,
    Day,
    DayRange,
} from '@amir04lm26/react-modern-calendar-date-picker';
import { MobileCalendar } from '@atoms/mobileCalendar';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';
import { useClickOutside } from '@utils/hooks/useClickOutside';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { DatesRange } from '@utils/types/calendar';
import { SegmentEventNamesEnum, SegmentEventPagesEnum, useAnalytics } from '@utils/hooks/useAnalytics';
import { ArrowRight, Calendar as CalendarIcon } from 'assets/icons';
import clsx from 'clsx';
import { addYears, differenceInDays, format } from 'date-fns';
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';

const DATE_FORMAT = 'MMM dd';

type ReservedDatesType = Day & { stringDate: string };

type Props = {
    onRangeSelected: (range: DatesRange) => void;
    showIcon: boolean;
    showButtonBorder: boolean;
    required: boolean;
    variant: 'primary' | 'secondary';
    value: DatesRange;
} & BaseComponentProps;

DateRangePicker.defaultProps = {
    showIcon: false,
    showButtonBorder: false,
    required: false,
    variant: 'primary',
    value: {
        from: null,
        to: null,
    },
};

/** Date range picker */
export function DateRangePicker(props: Omit<Props, 'onClick'>) {
    const {
        containerStyle,
        onRangeSelected,
        showIcon,
        showButtonBorder,
        variant,
        required,
        value,
    } = props;
    const [showCalendar, setShowCalendar] = useState(false);
    const { toggleBodyScroll, enableScroll } = useToggleBodyScroll();
    const isSecondary = useMemo(() => variant !== 'primary', [variant]);
    const isMobile = useIsMobile();
    const [calendarDates, setCalendarDates] = useState<DayRange>({
        from: null,
        to: null,
    });
    const [selectedDayRange, setSelectedDayRange] = useState<DatesRange>(value);
    const containerRef = useRef<HTMLDivElement>(null);
    const { trackEvent } = useAnalytics();
    const router = useRouter();
    const minDate: ReservedDatesType = useMemo(() => {
        const today = new Date();
        return {
            day: today.getDate(),
            month: today.getMonth() + 1,
            year: today.getFullYear(),
            stringDate: today.toISOString(),
        };
    }, []);
    const maxDate: ReservedDatesType = useMemo(() => {
        const oneYearFromToday = addYears(new Date(), 1);
        return {
            day: oneYearFromToday.getDate(),
            month: oneYearFromToday.getMonth() + 1,
            year: oneYearFromToday.getFullYear(),
            stringDate: oneYearFromToday.toISOString(),
        };
    }, []);

    const toggleCalendar = () => {
        isMobile && toggleBodyScroll();
        setShowCalendar((showCalendar) => !showCalendar);
    };

    const closeCalendar = () => {
        enableScroll();
        setShowCalendar(false);
    };

    useClickOutside(containerRef, closeCalendar);

    const clearDates = () => {
        setCalendarDates({ from: null, to: null });
        setSelectedDayRange({ from: null, to: null });
    };

    const selectDates = () => {
        const from = selectedDayRange.from;
        const to = selectedDayRange.to;
        if (!!onRangeSelected){
          if( from && to) {
            onRangeSelected({ from, to });
          } else if (!from && !to) {
            onRangeSelected({ from, to });
          }
          toggleCalendar();
        }
    };

    const setDateRange = (date: DayRange) => {
        setCalendarDates(date);
        // It's `month - 1` because DayRange.month January is 1 and Date.month January is 0
        setSelectedDayRange({
            from: date.from
                ? new Date(date.from.year, date.from.month - 1, date.from.day)
                : null,
            to: date.to
                ? new Date(date.to.year, date.to.month - 1, date.to.day)
                : null,
        });

        trackEvent(date.to ?
            SegmentEventNamesEnum.CHECK_OUT_DATES_APPLIED
            : SegmentEventNamesEnum.CHECK_IN_DATES_APPLIED,
          {
              PageName: router.pathname === '/home' ?
                SegmentEventPagesEnum.HOME_PAGE :
                SegmentEventPagesEnum.SEARCH_PAGE
          });
    };

    useEffect(() => {
        setSelectedDayRange(value);
    }, [value]);

    return (
        <div
            ref={containerRef}
            className={clsx([
                styles.container,
                containerStyle,
                isSecondary && styles.secondary,
            ])}
        >
            {isMobile ? (
                <>
                    <div
                        className={clsx([styles.button])}
                        onClick={toggleCalendar}
                    >
                        <div className={clsx([styles.label])}>
                            <div
                                className={clsx(
                                    showButtonBorder && styles.borderButton
                                )}
                            >
                                Check-In
                            </div>
                            &nbsp;-&nbsp;
                            <div
                                className={clsx(
                                    showButtonBorder && styles.borderButton
                                )}
                            >
                                Check-Out
                            </div>
                        </div>
                        {selectedDayRange.from && selectedDayRange.to ? (
                            <div className={styles.dates}>
                                {format(selectedDayRange.from, DATE_FORMAT)}
                                &nbsp;-&nbsp;
                                {format(selectedDayRange.to, DATE_FORMAT)}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                {differenceInDays(
                                    selectedDayRange.to,
                                    selectedDayRange.from
                                )}
                                &nbsp;days
                            </div>
                        ) : (
                            <span className={styles.placeholder}>
                                Select dates
                            </span>
                        )}
                    </div>
                    {showCalendar && (
                        <MobileCalendar
                            minimumDate={minDate.stringDate}
                            maximumDate={maxDate.stringDate}
                            required={required}
                            value={selectedDayRange}
                            selectDates={selectDates}
                            toggleCalendar={toggleCalendar}
                            onDatesChange={setSelectedDayRange}
                        />
                    )}
                </>
            ) : (
                <>
                    <div
                        className={clsx([styles.button])}
                        onClick={toggleCalendar}
                    >
                        {showIcon && <CalendarIcon className={styles.icon} />}
                        <div
                            className={clsx(
                                styles.dateButton,
                                showButtonBorder && styles.borderButton
                            )}
                        >
                            {selectedDayRange.from
                                ? format(selectedDayRange.from, DATE_FORMAT)
                                : 'Check-In '}
                        </div>
                        &nbsp;
                        {isMobile ? (
                            '-'
                        ) : (
                            <ArrowRight className={styles.arrow} />
                        )}
                        &nbsp;
                        <div
                            className={clsx(
                                styles.dateButton,
                                showButtonBorder && styles.borderButton
                            )}
                        >
                            {selectedDayRange.to
                                ? format(selectedDayRange.to, DATE_FORMAT)
                                : 'Check-Out'}
                        </div>
                    </div>
                    <div className={clsx(styles.calendarContainer)}>
                        {showCalendar && (
                            <Calendar
                                minimumDate={minDate}
                                maximumDate={maxDate}
                                colorPrimary="#70C5C1"
                                colorPrimaryLight="#70C5C122"
                                value={calendarDates}
                                onChange={setDateRange}
                                calendarClassName={clsx(styles.calendar)}
                                calendarSelectedDayClassName={clsx(
                                    styles.selectedDay
                                )}
                                renderFooter={() => {
                                    return (
                                        <div className={styles.footer}>
                                            <span
                                                className={clsx(
                                                    styles.footerSpan
                                                )}
                                                onClick={clearDates}
                                            >
                                                Clear dates
                                            </span>
                                            <span
                                                className={clsx(
                                                    styles.footerSpan
                                                )}
                                                onClick={selectDates}
                                            >
                                                Apply
                                            </span>
                                        </div>
                                    );
                                }}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
