import { Option } from '@atoms/select';
import { colors } from '@utils/constants/colors';
import { StylesConfig } from 'react-select';

export const primaryStyles = (isMobile: boolean): StylesConfig<Option> => ({
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Poppins',
    color: '#000000',
    whiteSpace: 'nowrap',
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '14px',
  }),
  container: (styles) => ({
    ...styles,
    flex: 8,
    width: '100%',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: isMobile ? '#ffffff00' : 'rgba(255, 255, 255, 0.5)',
    border: isMobile ? 0 : '1px solid black',
    borderRadius: 0,
    flex: 1,
    justifyContent: 'space-around',
    boxShadow: 'none',
    minHeight: '35px',
    ':hover': {
      border: isMobile ? 0 : '1px solid black',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '33px',
  }),
  dropdownIndicator: (style) => ({
    ...style,
    strokeWidth: 0,
    color: 'black',
    ':hover': {},
  }),
  option: (style) => ({
    ...style,
    ':hover': {
      background: `#aad4d277`,
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
  menu: (style) => ({
    ...style,
    backgroundColor: 'white',
    boxShadow: '0px 10px 10px 10px rgba(182, 182, 182, 0.25)',
    borderWidth: 1,
    borderColor: colors.roveBlack,
    borderStyle: 'solid',
    borderRadius: 0,
  }),
});
export const secondaryStyles = (isMobile: boolean): StylesConfig<Option> => ({
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Poppins',
    color: '$rove-black',
    fontWeight: '300',
    fontSize: 12,
    whiteSpace: 'nowrap',
  }),

  container: (styles) => ({
    ...styles,
    flex: 8,
    width: '100%',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff00',
    border: 0,
    flex: 1,
    justifyContent: 'space-around',
    boxShadow: 'none',
    minHeight: '35px',
    ':hover': {
      border: 0,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: !isMobile ? 'none' : 'initial',
    ...(!isMobile && {}),
  }),
  option: (style) => ({
    ...style,
    ':hover': {
      background: `#aad4d277`,
    },
  }),
  singleValue: (style) => ({
    ...style,
    color: '$rove-black',
    fontSize: 12,
  }),
  input: (style) => ({
    ...style,
    color: '$rove-black',
    fontSize: 12,
  }),
  menu: (style) => ({
    ...style,
    backgroundColor: 'white',
    boxShadow: '0px 10px 10px 10px rgba(182, 182, 182, 0.25)',
    borderWidth: 1,
    borderColor: colors.roveBlack,
    borderStyle: 'solid',
    borderRadius: 0,
  }),
});
