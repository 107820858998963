import { Button } from '@atoms/button';
import { Checkbox } from '@atoms/checkbox';
import { SearchBoxInput } from '@atoms/searchBoxInput';
import { primaryStyles, secondaryStyles } from '@atoms/select/selectStyles';
import { TextButton } from '@atoms/textButton';
import { Header } from '@organisms/header';
import { colors } from '@utils/constants/colors';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { ChevronLeft } from 'assets/icons';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect, { OnChangeValue } from 'react-select';
import styles from './styles.module.scss';

export type Option = {
  value: string | number;
  label: string;
};
export type Options = Option[];

type Props = {
  multiple: boolean;
  options: Option[];
  onSelected: (value: string) => void;
  placeholder?: string;
  value?: Option['value'] | null;
  required: boolean;
  variant: 'primary' | 'secondary';
  customEmptyValue?: Option;
} & BaseComponentProps;

Select.defaultProps = {
  multiple: false,
  required: false,
  placeholder: 'Select an option',
  variant: 'primary',
  value: '',
};

export function Select(props: Props) {
  const {
    containerStyle,
    options,
    onSelected,
    placeholder,
    multiple,
    value,
    required,
    variant,
    customEmptyValue,
  } = props;
  const isMobile = useIsMobile();
  const { toggleBodyScroll } = useToggleBodyScroll();
  const [selectedOption, setSelectedOption] = useState<Option | null>();
  const [showMobileSelector, setShowMobileSelector] = useState(false);
  const isSecondary = useMemo(() => variant === 'secondary', [variant]);

  const totalOptions = useMemo(
    () => [
      customEmptyValue || { label: placeholder ?? 'Select an option', value: '' },
      ...options,
    ],
    [options]
  );

  const toggleMobileSelector = () => {
    setShowMobileSelector((v) => !v);
    toggleBodyScroll();
  };

  const onChange = (option: OnChangeValue<Option, typeof multiple>) => {
    if (isEqual(selectedOption, option)) {
      setSelectedOption(null);
      return;
    }
    setSelectedOption(() => {
      const index = totalOptions.findIndex((o) => isEqual(o, option));
      if (!isMobile) {
        onSelected(totalOptions[index].value as string);
      }
      return index === -1 ? null : totalOptions[index];
    });
  };

  const clearSelection = () => {
    setSelectedOption(null);
  };

  const mobileSearchClick = () => {
    if (selectedOption) {
      onSelected(selectedOption.value as string);
    }
    toggleMobileSelector();
  };

  useEffect(() => {
    if (!value) {
      setSelectedOption(null);
    }
    setSelectedOption(() => {
      const index = totalOptions.findIndex((o) => isEqual(o.value, value));
      return index === -1 ? null : totalOptions[index];
    });
  }, [value]);

  return (
    <>
      <div
        className={clsx([
          styles.selectContainer,
          isSecondary && styles.secondary,
          containerStyle,
        ])}
      >
        {!isMobile ? (
          <>
            <ReactSelect<Option, typeof multiple>
              instanceId={'select'}
              isMulti={multiple}
              options={totalOptions}
              onChange={onChange}
              noOptionsMessage={() => <>No options available</>}
              placeholder={placeholder}
              value={selectedOption}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: colors.roveBlue,
                },
              })}
              styles={
                isSecondary
                  ? secondaryStyles(isMobile)
                  : primaryStyles(isMobile)
              }
              isSearchable={false}
            />
          </>
        ) : (
          <div
            className={clsx([
              styles.mobileButton,
              isSecondary && styles.secondary,
            ])}
            onClick={toggleMobileSelector}
          >
            <span>{placeholder}</span>
            <div>
              {selectedOption && selectedOption.value ? (
                <span className={clsx([styles.value])}>
                  {selectedOption.label}
                </span>
              ) : (
                <span className={clsx([styles.placeholder])}>
                  {placeholder}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      {isMobile && showMobileSelector && (
        <div className={styles.mobileSelector}>
          <Header />
          <div className={styles.header}>
            <TextButton
              label={
                <>
                  <ChevronLeft />
                  &nbsp;{placeholder}
                </>
              }
              onClick={toggleMobileSelector}
              containerStyle={styles.backButton}
            />
            <div className={styles.clear}>
              <TextButton
                label="Clear"
                onClick={clearSelection}
                containerStyle={styles.clearButton}
              />
            </div>
          </div>
          <div className={styles.body}>
            <SearchBoxInput
              placeholder={placeholder}
              containerStyle={styles.searchBar}
            />
            <div className={styles.list}>
              {options.map((option, idx) => (
                <Checkbox
                  key={idx}
                  containerStyle={styles.option}
                  value={selectedOption === option}
                  label={option.label}
                  onChange={() => onChange(option)}
                />
              ))}
            </div>
          </div>
          <div className={styles.mobileFooter}>
            <Button
              label={'Search'}
              onClick={mobileSearchClick}
              disabled={required && !selectedOption}
            />
          </div>
        </div>
      )}
    </>
  );
}
