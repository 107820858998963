import {
  BaseInputProps,
  InputChangeEvent,
  InputKeyboardEvent,
} from '@utils/types/baseComponents';
import { Lens } from 'assets/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import { colors } from '@utils/constants/colors';
import styles from './styles.module.scss';

type Props = {} & BaseInputProps;

SearchBoxInput.defaultProps = {
  placeholder: 'Search',
  onTextChange: (_value: string) => {},
  onPressEnter: (_value: string) => {},
};

/**
 * Default search box.
 */
export function SearchBoxInput(props: Props) {
  const { onPressEnter, onTextChange, placeholder, containerStyle } = props;
  const [value, setValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);

  const onChange = (ev: InputChangeEvent) => {
    const { value } = ev.target;
    setValue(value);
    onTextChange && onTextChange(value);
  };

  const onKeyDown = (ev: InputKeyboardEvent) => {
    if (ev.key === 'Enter') {
      onPressEnter && onPressEnter(value);
    }
  };

  return (
    <div
      className={clsx([
        styles.inputContainer,
        isFocused && styles.inputContainerFocused,
        containerStyle,
      ])}>
      <div className={clsx([styles.icon])}>
        <Lens stroke={isFocused ? colors.roveBlue : colors.roveBlack} />
      </div>
      <input
        className={clsx([styles.input])}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
